import styled from 'styled-components'

import { usePage } from '../hooks/resourceHooks'
import { makeMetadata } from '../utils/metadata'

import Page from '../containers/Page'
import H1 from '../components/Typography/H1'
import Intro from '../components/Typography/Intro'
import Error from '../pages/_error'

import {
  ContentBlock,
  ResourceBlock,
  HeaderBlock
} from '../components/Blocks'

const ContentfulH1 = styled(H1)`
  margin-top: 25px;
  margin-bottom: 0px;
`
const ContentfulIntro = styled(Intro)`
  min-height: 15px
`

const ContentfulPage = ({
  pathname,
  previews,
  hideChildren,
  pageSummary: pageSummaryOverride,
  showReturnToHomepage,
  children,
  childrenAtTheTop,
  errorCode,
  isLoading,
}) => {

  const page = usePage(pathname, previews)

  if (!isLoading && (errorCode || _.isEmpty(page))) {
    
    return <Error statusCode={errorCode || 404} />
  }

  const meta = makeMetadata(page)

  const {
    contentTitle,
    pageSummary,
    pageDescription
  } = page

  const resourceContent = pageSummaryOverride || pageSummary

  return (
    <Page meta={meta}>
      <HeaderBlock showReturnToHomepage={showReturnToHomepage}>
        <ContentfulH1>{contentTitle}</ContentfulH1>
        <ContentfulIntro>
          {pageSummaryOverride || (
            <ResourceBlock content={resourceContent} />
          )}
        </ContentfulIntro>
      </HeaderBlock>
      <ContentBlock>
      {isLoading ? <div>Loading...</div> : <>
      {childrenAtTheTop && !hideChildren && children}
        <ResourceBlock content={pageDescription} />
        {!childrenAtTheTop && !hideChildren && children}
      </>  
      }
      </ContentBlock>
    </Page>
  )
}

export default ContentfulPage
